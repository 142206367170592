/* inconsolata-400normal - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Inconsolata Regular '),
    local('Inconsolata-Regular'),
    url('./files/inconsolata-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inconsolata-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* inconsolata-700normal - latin */
@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Inconsolata Bold '),
    local('Inconsolata-Bold'),
    url('./files/inconsolata-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/inconsolata-latin-700.woff') format('woff'); /* Modern Browsers */
}

